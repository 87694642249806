/* eslint-disable camelcase */
import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function addSales() {
  // Use toast
  const toast = useToast();

  const saleAdd = ref({ type: { value: null } });

  const imagesId = [];
  const images = [];
  const refProductsTable = ref(null);

  // Products
  const perPage = ref(10);
  const totalProducts = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [25, 50]
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const countDescription = ref(1);
  const countSaleBulk = ref(1);
  const discount = ref(null);
  const toPrice = ref(null);
  const dateRange = ref({ startDate: new Date(), endDate: new Date() });

  const tableColumns = [
    { key: "select", sortable: false },
    { key: "title", sortable: false },
    { key: "region_id", label: "Region", sortable: false },
    { key: "type", sortable: false },
    { key: "price", sortable: false },

    { key: "status", sortable: false },
    { key: "created_at", sortable: false },
    { key: "created_by", sortable: false },
    { key: "approved_by", sortable: false },
    { key: "action" },
  ];

  const dataMeta = computed(() => {
    const localItemsCount = refProductsTable.value
      ? refProductsTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    };
  });

  const bulkConfigs = [
    {
      quantity: "",
      discount: "",
    },
  ];
  const arrayDescription = [
    {
      region_id: "",
      title: "",
      description: "",
    },
  ];

  const refetchData = () => {
    refProductsTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });
  return {
    saleAdd,
    perPage,
    perPageOptions,
    currentPage,
    dataMeta,
    refProductsTable,
    searchQuery,
    tableColumns,
    sortBy,
    isSortDirDesc,
    dateRange,
    images,
    discount,
    toPrice,
    arrayDescription,
    bulkConfigs,
    countDescription,
    countSaleBulk,
    imagesId,
  };
}
