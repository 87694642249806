<template>
  <b-card class="sale-add-wrapper">
    <validation-observer ref="addSaleValidation">
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <!-- Title -->
          <b-col md="6">
            <b-form-group
              label="Title"
              label-for="sale-add-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="sale-add-title"
                  v-model="saleAdd.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Type -->
          <b-col md="6">
            <b-form-group
              v-if="saleType.length > 0"
              label="Type"
              label-for="sale-add-type"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  id="sale-add-type"
                  v-model="saleAdd.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="saleType"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Region -->
          <b-col md="6">
            <b-form-group
              label="Region"
              label-for="sale-add-region"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Region"
                rules="required"
              >
                <v-select
                  id="sale-add-region"
                  v-model="saleAdd.region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  @input="enableMultipleSelect"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Select Date Range -->
          <b-col md="6">
            <b-form-group
              label="Select Date Range"
              label-for="sale-add-date-range"
              class="mb-2"
            >
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                opens="opens-right"
                :auto-apply="true"
              >
                <template v-slot:input="picker">
                  {{ formatDateDDMMYYYY(picker.startDate) }} - {{ formatDateDDMMYYYY(picker.endDate) }}
                </template>
              </date-range-picker>
            </b-form-group>
          </b-col>

          <!-- Bulk Sale Config -->
          <b-col
            v-if="saleAdd.type.value === 'bulk'"
            md="12"
            class="mb-2"
          >
            <label for="sale-config">General config for sale campaign</label>
            <transition-group
              id="sale-config"
              name="custom-classes"
              enter-active-class="animate__animated animate__fadeInDown"
              leave-active-class="animate__animated animate__fadeOutUp"
              :duration="300"
            >
              <b-card-body
                v-for="index in countSaleBulk"
                :key="index"
              >
                <!-- repeater from -->
                <b-col cols="12">
                  <div class="border rounded p-2">
                    <div
                      v-if="bulkConfigs.length > 1"
                      class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                    >
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeItem(index - 1)"
                      />
                    </div>
                    <!-- Quantity -->
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Quantity"
                          label-for="sale-quantity"
                          class="mb-2"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Quantity"
                            rules="required"
                          >
                            <b-input-group>
                              <b-form-input
                                id="sale-quantity"
                                v-model="bulkConfigs[index - 1].quantity"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Discount -->
                      <b-col md="6">
                        <b-form-group
                          label="Discount"
                          label-for="quantity-discount"
                          class="mb-2"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Discount"
                            rules="required"
                          >
                            <b-input-group>
                              <b-form-input
                                id="quantity-discount"
                                v-model="bulkConfigs[index - 1].discount"
                                type="number"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-card-body>
            </transition-group>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormSaleBulk"
              >
                Add config
              </b-button>
            </b-col>
          </b-col>
          <!-- Batch Config -->
          <b-col
            v-if="saleAdd.type.value === 'batch'"
            md="6"
          >
            <b-form-group
              label="Sale Percent"
              label-for="sale-percent"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sale Percent"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="sale-percent"
                    v-model="salePercent"
                    step="0.01"
                    min="0.00"
                    max="100.00"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="saleAdd.type.value === 'batch'"
            md="6"
          >
            <b-form-group
              label="Quantity"
              label-for="quantity"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Quantity"
                rules=""
              >
                <b-input-group>
                  <b-form-input
                    id="quantity"
                    v-model="saleQuantity"
                    step="1"
                    min="1"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Coupon Config -->
          <b-col
            v-if="saleAdd.type.value === 'coupon'"
            md="6"
          >
            <b-form-group
              label="Sale Price"
              label-for="sale-price"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sale Price"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="sale-price"
                    v-model="salePrice"
                    step="0.01"
                    min="0.00"
                    max="100.00"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Products -->
          <!-- <b-col md="12">
            <b-form-group
              label="Assets"
              label-for="sale-add-products"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Assets"
                rules="required"
              >
                <multiselect
                  v-model="selectedProducts"
                  :disabled="disabledMultipleSelect"
                  label="title"
                  track-by="id"
                  placeholder="Type to search assets"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="fetchProducts"
                  @open="fetchProducts"
                >
                  <template
                    slot="tag"
                    slot-scope="{ option, remove }"
                  >
                    <span
                      class="tag custom__tag"
                      @click="remove(option)"
                    >{{ option.title }}</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- Products -->
          <b-col md="12">
            <b-form-group
              label="Assets"
              label-for="sale-add-products"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Assets"
                rules="required"
              >
                <v-select
                  :disabled="disabledMultipleSelect"
                  v-model="selectedProducts"
                  multiple
                  label="title"
                  :options="products"
                  :filterable="false"
                  @open="fetchProducts"
                  @search="fetchProducts"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Action -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveSale"
            >
              Save sale
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :to="{ name: 'sale-campaign' }"
              variant="outline-secondary"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </validation-observer>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import vSelect from 'vue-select'

import {
  BCard,
  BMedia,
  BForm,
  BRow,
  BCol,
  BBadge,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BCardBody,
  BInputGroup,
  BPagination,
  BFormCheckbox,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import * as config from '@/utils/config'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import addSales from './addSale'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  components: {
    BFormCheckbox,
    BCardBody,
    BLink,
    BBadge,
    BInputGroup,
    BCard,
    BForm,
    BRow,
    BCol,
    BTable,
    BButton,
    BMedia,
    BFormGroup,
    BFormInput,
    BPagination,
    VSelect,
    ValidationProvider,
    ValidationObserver,
    DateRangePicker,
    Multiselect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
    ...mapGetters('app', ['regionPriceOptions']),
    ...mapGetters('product', ['productType']),
    ...mapGetters('product', ['productTags']),
    ...mapGetters('product', ['productCategory']),
    ...mapGetters('sale', ['saleType']),
  },
  async created() {
    await store.dispatch('product/fetchTags')
  },
  data() {
    return {
      salePrice: null,
      salePercent: null,
      saleQuantity: 1,
      products: [],
      isLoading: false,
      active: 'Active',
      inactive: 'Inactive',
      snowOption: {
        placeholder: '',
        theme: 'snow',
      },
      readOnlyOption: {
        placeholder: 'Please choose region',
        theme: 'snow',
      },
      required,
    }
  },
  created() {
    store.dispatch('product/fetchCategories')
    store.dispatch('product/fetchTags')
  },

  setup() {
    const disabledMultipleSelect = ref(true)
    const selectedProducts = ref([])
    watch(
      () => selectedProducts.value,
      val => {
        // This will execute when I click the button every time
      },
    )
    const {
      saleAdd,
      perPage,
      perPageOptions,
      currentPage,
      refProductsTable,
      tableColumns,
      sortBy,
      isSortDirDesc,
      dataMeta,
      totalProducts,
      searchQuery,
      dateRangeValue,
      dateRange,
      images,
      fromPrice,
      toPrice,
      countDescription,
      countSaleBulk,
      imagesId,
      // Repeat form
      bulkConfigs,
      arrayDescription,
    } = addSales()
    return {
      disabledMultipleSelect,
      selectedProducts,
      perPageOptions,
      perPage,
      currentPage,
      refProductsTable,
      tableColumns,
      isSortDirDesc,
      dataMeta,
      totalProducts,
      dateRangeValue,
      dateRange,
      searchQuery,
      sortBy,
      config,
      saleAdd,
      images,
      fromPrice,
      toPrice,
      imagesId,
      // Repeat form
      bulkConfigs,
      arrayDescription,
      countDescription,
      countSaleBulk,
    }
  },
  methods: {
    enableMultipleSelect() {
      if (this.saleAdd.region) {
        this.disabledMultipleSelect = false
      } else {
        this.disabledMultipleSelect = true
        this.selectedProducts = []
      }
    },
    fetchProducts(searchQuery) {
      this.isLoading = true
      store.dispatch('product/fetchProducts', {
        search: searchQuery,
        limit: 10,
        page: 1,
        region_ids: this.saleAdd.region.value,
      })
        .then(response => {
          this.products = response.body.data
          this.isLoading = false
        })
    },
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        title: '',
        description: '',
        region_id: '',
      })
      this.countDescription += 1
    },
    addNewItemInItemFormSaleBulk() {
      this.bulkConfigs.push({
        quantity: '',
        discount: '',
      })
      this.countSaleBulk += 1
    },
    removeItemDescription(index) {
      this.arrayDescription.splice(index, 1)
      this.countDescription -= 1
    },
    removeItem(index) {
      this.bulkConfigs.splice(index, 1)
      this.countSaleBulk -= 1
    },
    saveSale() {
      this.$refs.addSaleValidation.validate().then(success => {
        if (success) {
          const payload = {
            region: this.saleAdd.region.value,
            name: this.saleAdd.title,
            type: this.saleAdd.type.value,
            batchDiscountPercent: this.saleAdd.type.value === 'batch' ? this.salePercent : null,
            fixPriceDiscount: this.saleAdd.type.value === 'coupon' ? this.salePrice : null,
            bulkConfigs: this.saleAdd.type.value === 'bulk' ? this.bulkConfigs.map(x => ({
              amount: x.quantity,
              percent: x.discount,
            })) : null,
            quantity: this.saleQuantity,
            productIds: this.selectedProducts.map(x => x.id),
            startTime: this.dateRange.startDate,
            endTime: this.dateRange.endDate,
            isEndable: true,
          }
          store.dispatch('sale/addSale', payload).then(response => {
            if (response.request.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Create asset successfuly',
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              router.push('/sale-campaign')
            }
          }).catch(e => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${e.response.data.message}`,
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    formatDateDDMMYYYY(time) {
      return moment(time).format('DD/MM/YYYY')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";
// @import '@core/scss/vue/libs/swiper.scss';


/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}

.vue-daterange-picker {
  width: 100%
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  span {
    float: left;
  }
}

.tag {
  background: #41b883;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #41b883;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
  color: white;
}

.tag:hover::after {
   border-left-color: crimson;
}
</style>
